import React from 'react'
import { TopNavComponent } from '../../components/layout/header/Index'
import { Box } from '@mui/material'
import { FooterCompoment } from '../../components/layout/footer/Index'
import PageHeaderComponent from '../../components/shared/PageHeaderComponent/PageHeaderComponent'
import FiltersFile from '../../components/filters/Filters'

import Styles from "./AllProductsPage.module.scss";
export default function AllProductsPage() {

    return (
        <div className={`padding2 ${Styles.container}`}>
            <TopNavComponent />

            <Box my={2}>
                <PageHeaderComponent back={true} title="All Products" />
            </Box>
            <FiltersFile />
            <FooterCompoment />
        </div>
    )
}
