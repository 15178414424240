import React, { useState, useRef } from "react";
import { motion, useInView } from "framer-motion";
import StarHalfOutlinedIcon from '@mui/icons-material/StarHalfOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Stack } from "@mui/material";
import styles from "./index.module.scss";

import CAnimatePresence from "../../framerMotion/AnimatePresence";


import { CardPrice, CardTitle } from "../../atoms/text/CardTitle";
import WishlistIcon from "../../wishlistIcon/WishlistIcon";
import ProductDetailsPage from "../../productDetailsPage/ProductDetailPage";
import IconBtn from "../../atoms/IconBtn/IconBtn";

export default function ProductCard({ props }) {

  const { sno, item_name, item_price, item_image, item_rating, } = props;
  const [selectId, setSelectedId] = useState(null)
  const imageUrl = "https://wholphintech.com/werp/main/server/products/uploaded_item_images/"
  const productImages = item_image?.split(",").map((item) => ({
    imageUrl: imageUrl + item
  }))

  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })

  return (

    <>
      <motion.div
        // whileHover={{ scale: 1.1 }}

        layoutId={sno}

        onClick={() => {
          setSelectedId(sno);
        }
        }
        style={{

          // transform: isInView ? "none" : "translateX(-200px)",
          // transform: isInView ? "none" : "scale(1)",
          // opacity: isInView ? 1 : 0,
          // transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s"
        }}
      // initial={{ opacity: 0, scale: 0 }}
      // animate={{ opacity: 1, scale: isInView ? 1 : 0 }}

      >
        <motion.div
          className={`${styles.MostPopularCard} `}
          ref={ref}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: isInView ? 1 : 0 }}
          transition={{
            delayChildren: 0.1,
            // staggerDirection: -1
          }}
        >
          <motion.div
            key={sno}
            style={{
              backgroundImage: `url(${productImages[0].imageUrl})`,
              // transform: isInView ? "none" : "translateX(-200px)",
              // transform: isInView ? "none" : "scale(1)",
              // opacity: isInView ? 1 : 0,
              // transition: "all 0.4s "
              // transition: "all 0.2s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s"
            }}
            className={styles.cardImg}
          >
            <WishlistIcon />
            <div className={styles.cardFooter}>
              <div className={styles.ratings}>{item_rating ? item_rating : "1.2 k"}</div>
              <StarHalfOutlinedIcon sx={{ color: "var(--textColor)", fontSize: "18px" }} />
            </div>
          </motion.div>
          <Stack gap={"8px"}>
            <CardTitle>{item_name}</CardTitle>
            <CardPrice>${item_price}</CardPrice>
          </Stack>
        </motion.div>
      </motion.div >
      {
        selectId &&
        <CAnimatePresence id={selectId} onToggleExpand={() => {
          setSelectedId(null);
        }}>
          {/* <div className="paddi">
            <ProductDetailsPage item={props} images={productImages}
              close={() => {
                setSelectedId(null)
              }}
            />
          </div> */}

          <Box className="padding2"  sx={{ width: {xs:"100%", md:"100vw" }}}>
            <div className={`${styles.popupScreen} `}>
              {
                // close && 
                <div onClick={() => setSelectedId(null)} className={styles.closeBtn}>
                  <IconBtn sx={{ border: "1px solid", }}>
                    <CloseOutlinedIcon fontSize="small" />
                  </IconBtn>
                </div>
              }
              <ProductDetailsPage byId={sno} images={productImages}
                close={() => {
                  setSelectedId(null)
                }}
              />
            </div>
          </Box>
        </CAnimatePresence>
      }

    </>

  );
}
