import React, { useEffect, useState } from 'react';
import Styles from "./OnGoing.module.scss";
import { useUserOrdersApiMutation, useUserOrdersApiQueryQuery } from '../../../../store/slice';
import ProductCard from '../../../atoms/Card/productCard/ProductCard';
import CartImage from '../../../atoms/thumbnails/CartImage';
import { productsImageUrl } from '../../../../config/config';
import { CardTitle, CardPrice } from '../../../atoms/text/CardTitle';
import { Box, Grid, Stack } from '@mui/material';
import InfoCard from '../../../atoms/Card/InfoCard/InfoCard';
import MButton from '../../../atoms/button/MButton';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EmptyCart from '../../../EmptyCart/EmptyCart';
import { LoaderCircle } from '../../../atoms/Skeletons/SkeletonProduct';

function OrdersPage(props) {
    const { tab, } = props
    const navigate = useNavigate()
    const userData = useSelector((state) => state.products.userData)

    const formdata = new FormData()
    formdata.append("user_mobile", userData?.mobile_number)
    const [getOrdersApi, { isLoading: orderApiLoading, isSuccess }] = useUserOrdersApiMutation()

    const [ordersApi, setOrdersApi] = useState([])
    const [loading, setLoading] = useState(true)

    const pendingOrders = ordersApi?.filter((item) => item?.order_status?.toLowerCase() !== "delivered_by_agent")
    const deliveryOrders = ordersApi?.filter((item) => item?.order_status?.toLowerCase() === "delivered_by_agent")

    useEffect(() => {
        const apiCall = async () => {
            let res = await getOrdersApi(formdata)
            console.log("res", res);
            if (res?.error?.data?.toLowerCase()?.trim() === "no_details_exist") {
                console.log("no_details_exist", res);
            } else if (res?.data) {
                setOrdersApi(res?.data)
                setTimeout(() => setLoading(false), 2000)

            }
        }
        apiCall()
    }, [])

    return (
        < >
            <Grid container spacing={3} mt={1} mb={{ xs: 16, sm: 10 }}>
                {
                    loading && (tab === "Ongoing") ?
                        <Grid item xs={12} sx={{ minHeight: "70vh", flex: 1 }}  >
                            <LoaderCircle />
                        </Grid>
                        :
                        (pendingOrders?.length > 0) && (tab === "Ongoing") ?
                            pendingOrders?.map((item, index) => {
                                return (
                                    <Grid item xs={12} md={6} key={item?.id}>
                                        <OnGoingCard item={item} {...props} />
                                    </Grid>
                                )
                            })
                            :
                            <>
                                {tab === "Ongoing" &&
                                    <EmptyCart
                                        title="You don't have an order yet"
                                        desc="You don't have an ongoing orders at this time"
                                        btnText="Return To Shop"
                                        onClick={() => navigate("/")}
                                    />
                                }
                            </>
                }

                {
                    loading && (tab === "Completed") ?
                        <Grid item xs={12} sx={{ minHeight: "70vh", flex: 1 }}  >
                            <LoaderCircle />
                        </Grid>
                        :
                        ((tab === "Completed") && (deliveryOrders?.length > 0)) ?
                            deliveryOrders?.map((item, index) => {
                                return (
                                    <Grid item xs={12} md={6} key={item?.id}>
                                        <OnGoingCard item={item} {...props} />
                                    </Grid>
                                )
                            })
                            :
                            <>
                                {
                                    (tab === "Completed") &&
                                    <EmptyCart
                                        title="You don't have an Completed yet"
                                        desc="You don't have an Completed orders at this time"
                                        btnText="Return To Shop"
                                        onClick={() => navigate("/")}
                                    />
                                }
                            </>
                }
            </Grid>
        </>
    )
}

export default OrdersPage



const OnGoingCard = (props) => {
    const { item } = props;
    const imageUrl = item?.item_image?.split(",")[0]
    return (
        <ProductCard>
            <CartImage imageUrl={imageUrl} />
            <RightSideContent {...props} />
        </ProductCard>
    )

}


const RightSideContent = (props) => {
    const { item, setReview, setTrack, setGetProduct } = props
    const itemName = item.item_name.split(",")
    const ItemPrice = item.item_price.split(",")
    let totalPrice = 0;

    for (let i = 0; i < ItemPrice.length; i++) {
        totalPrice = totalPrice + Number(ItemPrice[i])
    }

    const onClickReview = () => {
        setReview(true)
        setGetProduct(item)
    }

    const NavTracker = () => {
        setTrack(true)
        setGetProduct(item)

    }

    return (
        <div className={Styles.contentBox}>
            <CardTitle>{itemName[0]}</CardTitle>
            {/* {(item.order_status === "pending") && <InfoCard>In Delivery</InfoCard>} */}
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                {(item.order_status !== "delivered_by_agent") && <InfoCard>In Delivery</InfoCard>}
                {(item.order_status === "delivered_by_agent") && <InfoCard>Completed</InfoCard>}
                <div>
                    <InfoCard>Order Id:{item?.id} </InfoCard>
                </div>
            </Stack>
            <div className={Styles.bottomBox}>
                <CardPrice>${totalPrice}</CardPrice>

                {/* {
                    item.order_status === "pending" &&
                    <Box width={"120px"} maxWidth="125px">
                        <MButton fullWidth height="32px" fweight={500} onClick={NavTracker}>Track Order</MButton>
                    </Box>
                } */}
                {
                    item.order_status !== "delivered_by_agent" &&
                    <Box width={"120px"} maxWidth="125px">
                        <MButton fullWidth height="32px" fweight={500} onClick={NavTracker}>Track Order</MButton>
                    </Box>
                }
                {
                    (item.order_status === "delivered_by_agent") &&
                    <Box width={"130px"} maxWidth="130px">
                        <MButton fullWidth height="32px" fweight={500} onClick={onClickReview}>Leave Review</MButton>
                    </Box>
                }

            </div>
        </div>
    )
}

