import { Box, Grid } from "@mui/material";
import { motion } from "framer-motion";
import Styles from "./GetNearByShops1.module.css";

import Text from "../atoms/text/Text";
import { useGetNearByShopsMutation, useGetNearByShopsQuery } from "../../store/slice";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleMap from "../googleMap/CGoogleMap2";
import CGoogleMap1 from "../googleMap/CGoogleMap1";
import CGoogleMap from "../googleMap/CGoogleMap";
import CGoogleMap2 from "../googleMap/CGoogleMap2";
import commonImg from "./appliances.png"
import MapsKeyWrapper from "../googleMap/MapsKeyWrapper";

const shopsImgUrl = "https://wholphintech.com/werp/main/server/shops/uploads/"

export default function GetNearByShops1() {
  const navigate = useNavigate()

  const { data: nearByshopsApi } = useGetNearByShopsQuery()


  // const [shopsList, setShopsList] = useState([])
  const shopsList = nearByshopsApi ? nearByshopsApi : []
  const [coords, setCooreds] = useState({
    lat: -3.745,
    lng: -38.523
  })

  console.log("checking", nearByshopsApi);


  const navigateProductPage = (data) => {
    navigate(`/shop/${data?.shop_id}`)
  }


  const DetectCurrentLocation = () => {
    if (navigator.geolocation) {
      let a = navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude, accuracy } = position.coords;
        setCooreds({
          lat: latitude,
          lng: longitude
        })
      });
    }
  }
  useEffect(() => { DetectCurrentLocation() }, [])

  return (
    <Box className={Styles.container}>

      <Box className={Styles.heading}>
        {/* <Text className={Styles.subtitle}>Unbeatable deals and discounts</Text> */}
        <Text className={Styles.title}>Near by shops</Text>
      </Box>
      <Box >

      </Box>
      <section className={Styles.cardContainer}>

        <Box sx={{
          height: "240px",
          maxHeight: "inherit",
          p: "4px",
          display: { xs: "none", sm: "block" }
        }}
          className={Styles.offerContentContainer}>
          {/* <Box className={Styles.validityContainer}>
            <Text className={Styles.validityText}>Near by shops</Text>
          </Box> */}
          {/* <Box className={Styles.offerTextContainer}>
            <Box className={Styles.discountContainer}>
              <Text className={Styles.txtMd}>Up to</Text>
              <Text className={Styles.bldXl}>
                {discount}% <span className={Styles.txtMd}>off</span>
              </Text>
            </Box>
            <Box className={Styles.minPurchaseContainer}>
              <Text className={Styles.bldMd}>{coins} NeuCoins*</Text>
              <Text className={Styles.txtSm}>On min purchase of ${minPurchase}</Text>
            </Box>
          </Box> */}
          <MapsKeyWrapper>
            <CGoogleMap1 coords={coords} />
          </MapsKeyWrapper>
        </Box>

        <section className={Styles.shopImagesContainer}>
          {shopsList?.map((shop) => (
            <div onClick={() => navigateProductPage(shop)}>
              <motion.div
                whileTap={{ scale: 0.1 }}
                key={shop.shop_id}
                className={Styles.shopImageBox}
              >
                <div className={Styles.shopImage} style={{ backgroundImage: `url(${shop?.photo ? (shopsImgUrl + shop?.photo) : commonImg})` }} />
                <Text className={Styles.shopName}>{shop.name}</Text>
              </motion.div>
            </div>
          ))}
        </section>
      </section>
    </Box>
  );
}
