import React, { useEffect, useRef, useState } from 'react'
import Styles from "./TopnavSearchComponent.module.scss";
import { Link } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import { useGetAllProductsQuery } from '../../../store/slice';
import { motion } from 'framer-motion';
import useDebounce from '../../../hooks/useDebouce';
import { Grid, Skeleton } from '@mui/material';

export default function TopnavSearchComponent() {

    const { data: products } = useGetAllProductsQuery()
    const [inputValue, setInputValue] = useState("")
    const [isListBoxVisible, setIsListBoxVisible] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const containerRef = useRef(null);

    const debouncedSearch = useDebounce(inputValue, 1000);

    const recentSearchItem = [
        { title: "Digni Monaqi AK-4 Womens Wedges Sandal", id: "1303", },
        { title: "Highlander", id: "2157" },
        { title: "Campus Sutra", id: "2155" }
    ];
    const apiListItems = products?.map((item) => ({
        title: item.item_name,
        id: item.sno
    }))

    const handleFocus = () => {
        setIsListBoxVisible(true);
    };

    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setIsListBoxVisible(false);
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const onChangeInput = (e) => {
        setInputValue(e.target.value)
        setIsListBoxVisible(true);
    }
    const animationVariants = {
        hidden: { height: 0, opacity: 0 },
        visible: { height: 400, opacity: 1 },
    };

  
    const filteredApiListItems = apiListItems
        ?.filter((item) => item?.title?.toLowerCase()?.includes(debouncedSearch?.toLowerCase()))
        .slice(0, 20);

        useEffect(() => {
            setShowSkeleton(true);
            setTimeout(() => {
                setShowSkeleton(false);
            }, 1000);
        }, [debouncedSearch]);
    return (
        <div className={Styles.container}
            ref={containerRef}
        >
            {/* <div className={Styles.inputContainer}> */}
            <div className={Styles.searhIconBox}>
                <SearchOutlinedIcon className={Styles.searchIcon} />
            </div>
            <input className={Styles.input}
                onFocus={handleFocus}
                placeholder='What are you lookin for?'
                onChange={onChangeInput}
            />
            {/* </div> */}


            <motion.div
                initial={isListBoxVisible ? "visible" : "hidden"}
                animate={isListBoxVisible ? "visible" : "hidden"}
                variants={animationVariants}
                transition={{ duration: 0.3 }}
                className={`${Styles.optionListBox} `}
            // style={{ overflow: 'hidden' }}
            >
                <div className={Styles.listItems}>
                    {
                        recentSearchItem.filter((item) => item?.title?.toLowerCase().includes(debouncedSearch?.toLowerCase()))
                            .map((product, index) => {
                                return (
                                    <Link key={index}
                                        to={`/products/${product.id}`}
                                        className={Styles.recentOptionItem}
                                        onClick={() => setIsListBoxVisible(false)}

                                    >
                                        <SearchOutlinedIcon fontSize='small' />
                                        <span> {product.title}</span>
                                    </Link>
                                )
                            })
                    }

                    {
                        
                        !showSkeleton ?
                            filteredApiListItems?.map((item, index) => {
                                return (
                                    <>
                                        <Link key={index}
                                            // to={item.url} 
                                            to={`/products/${item.id}?`}
                                            className={Styles.productsOptionItem}
                                            onClick={() => setIsListBoxVisible(false)}
                                        >
                                            <DynamicFeedOutlinedIcon fontSize='small' />
                                            <span>{item.title} </span>
                                        </Link>
                                       
                                    </>
                                )
                            })
                            :
                            (
                                new Array(10).fill(0).map((_, index) => (
                                    <Grid key={index} item xs={6} sm={4} md={3} lg={3}>
                                       
                                        <Skeleton variant="text" width={"40%"} />
                                    </Grid>
                                )

                                )
                            )

                           
                    }
                </div>
            </motion.div>
        </div>
    )
}
