
import React, { forwardRef } from 'react';
import { TextField } from '@mui/material';
import Styles from './InputStyles.module.css';
import { styled } from '@mui/material/styles';
import { Padding } from '@mui/icons-material';

const CssTextField = styled(TextField)({
  boxSizing: "border-box",
  // maxHeight: "48px",

  "& input::placeholder": {
    // color: "var(--textColor3)",
    // fontSize: "14px",
    // fontWeight: "400",
    fontSize: "14px",
    fontWeight: 600,
    letterSpacing: "0.20px",
  },
  '& .MuiOutlinedInput-root': {
    color: "var(--textColor1)",
    fontSize: "15px",
    fontWeight: 600,
    background: "var(--gray)",
    borderRadius: "12px",
    // minHeight: "22px",
    padding: "3px 4px",
    '& fieldset': {
      border: "none",
      maxHeight: "48px",

    },
    '&:hover fieldset': {
      border: "0.1px solid var(--grey)",
    },
    '&.Mui-focused fieldset': {
      border: "0.1px solid var(--primary-color1)",
      boxShadow: "rgba(22, 119, 225, 0.2 ) 0px 0px 0px 2px"
    },


    // "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    //   display: "none",
    // },
    // "& input[type=number]": {
    //   MozAppearance: "textfield",
    // },
  },
});

const InputBare = ({ onChange, value, placeholder, onBlur, defaultValue, type, name, minRows, size = "small" }, ref) => {

  return (
    <div className={Styles.container}>

      <CssTextField id="custom-css-outlined-input"
        inputRef={ref}
        size={size}
        // margin="none"
        type={type && type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        autoComplete='off'
        multiline={minRows && minRows}
        minRows={minRows}
         required
         inputProps={{
           maxLength: 10,
           pattern:"[0-9]*"
         }}
      />

    </div>
  );
};

const Input = forwardRef(InputBare)

export default Input;