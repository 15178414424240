import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProductCard from '../../components/shared/productCard/Index'
import { useGetAllProductsQuery, useGetNearByShopsQuery, useGetProductsByShopIdMutation } from '../../store/slice'
import { TopNavComponent } from '../../components/layout/header/Index'
import { FooterCompoment } from '../../components/layout/footer/Index'
import PageHeaderComponent from '../../components/shared/PageHeaderComponent/PageHeaderComponent'
import { useLocation, useParams } from 'react-router-dom'
import SkeletonProduct from '../../components/atoms/Skeletons/SkeletonProduct'
import Styles from "./ProductsByShopId.module.scss";
function ProductsByShopId() {
    const location = useLocation()
    const { shopId } = useParams()
    // const [getAllProducts, setGetAllProducts] = useState()
    // const [getShopByIdApi, { isLoading: statusLoading, isSuccess: apiCallSuccess }] = useGetProductsByShopIdMutation();
    const { data: getAllProducts1, isLoading: statusLoading, isSuccess: apiCallSuccess } = useGetAllProductsQuery()
    const { data: nearByshopsApi } = useGetNearByShopsQuery()


    const getAllProducts = getAllProducts1?.filter((item) => item.shop_id === shopId)
    const shopName = nearByshopsApi.find((item) => item?.shop_id === shopId)
    console.log("shopName", shopName);

    return (
        <div className={`padding2 ${Styles.pageContainer}`}>
            <TopNavComponent />
            <section className={`backBtnSticky ${Styles.headerSection}`}>
                <PageHeaderComponent
                    back={true}
                    title={`  ${shopName?.name}`}

                />
            </section>
            <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
                {
                    apiCallSuccess ?
                        getAllProducts?.map((item) => {
                            return (
                                <Grid key={item.sno} item xs={6} sm={4} md={3} lg={3}>
                                    <ProductCard props={item} />
                                </Grid>
                            )
                        })
                        :


                        [...Array(15)].map((_, index) => {
                            return (
                                <Grid key={index} item xs={6} sm={4} md={3} lg={3}>
                                    <SkeletonProduct />
                                </Grid>
                            )
                        })


                }
            </Grid>

            <FooterCompoment />
        </div>
    )
}

export default ProductsByShopId