import React, { useEffect, useRef, useState } from 'react'
import Styles from "./AddNewAddress1.module.scss";
import ImageThumbnail from '../../atoms/thumbnails/ImageThumbnail/ImageThumbnail';
import { addAddressMapImg } from '../../../assets/icons';
import MButton from '../../atoms/button/MButton';
import { Box, Grid, Stack } from '@mui/material';
import BaseButton from '../../atoms/button/baseButton';
import Text from '../../atoms/text/Text';
import LabelText from '../../atoms/text/LabelText';
import { useUserProfileMutation, useUserProfileUpdateMutation } from '../../../store/slice';
import { addAddress, setUserProfileData } from '../../../store/reducers/Index';
import { useDispatch, useSelector } from 'react-redux';
import PageHeaderComponent from '../../shared/PageHeaderComponent/PageHeaderComponent';
import CGoogleMap from '../../googleMap/CGoogleMap';
import CGoogleMap1 from '../../googleMap/CGoogleMap1';
import GSearchInput from '../../googleMap/GSearchInput';
import SimpleMap from '../../googleMap/CGoogleMap2';
import MapsKeyWrapper from '../../googleMap/MapsKeyWrapper';
import Input from '../../atoms/Input/Input';

const AddNewAddress1 = (props) => {
  const { close, } = props;

  const [coords, setCooreds] = useState({
    lat: -3.745,
    lng: -38.523
  })

  const [userprofileUpadteApi] = useUserProfileUpdateMutation()
  const [getUserProfileApi] = useUserProfileMutation()
  const dispatch = useDispatch()

  const loginUser = useSelector((state) => state.products.userData)
  const profileData = useSelector((state) => state.products.userData)

  const userNameRef = useRef("")
  const userMobileRef = useRef("")
  const userAddressNameRef = useRef("")
  const userAddressAreaRef = useRef("")

  useEffect(() => {
    if (profileData) {
      if (userNameRef.current) userNameRef.current.value = profileData.name || "";
      if (userMobileRef.current) userMobileRef.current.value = profileData.mobile_number || "";
      if (userAddressNameRef.current) userAddressNameRef.current.value = profileData.address || "";
      if (userAddressAreaRef.current) userAddressAreaRef.current.value = profileData.user_area || "";
    }
  }, [profileData]);

  const submitAddres = async () => {
    const payload1 = {
      name: userAddressNameRef && userAddressNameRef?.current.value,
      text: userAddressAreaRef && userAddressNameRef?.current.value
    }
    const payload = new FormData();
    payload.append("user_name", userNameRef.current.value)
    payload.append("user_mobile", loginUser?.mobile_number ? loginUser?.mobile_number : userMobileRef.current.value)
    payload.append("user_address", userAddressNameRef.current.value)
    payload.append("user_area", userAddressAreaRef.current.value)
    payload.append("lat", coords?.lat)
    payload.append("lon", coords?.lng)

    const res = await userprofileUpadteApi(payload)
    console.log("ress", res);
    if (res.error.data.trim() === "empty") {
      alert("please enter all fields ")
    } else if (res.error.data.trim() === "updated") {
      dispatch(addAddress(payload1))
      const getUserPayload = new FormData()
      getUserPayload.append("id", loginUser?.mobile_number)
      const userDataRes = await getUserProfileApi(getUserPayload)
      console.log("userUpdated", userDataRes);
      dispatch(setUserProfileData(userDataRes?.data && userDataRes?.data[0]))
      close()
    }

  }

  const DetectCurrentLocation = () => {
    if (navigator.geolocation) {
      let a = navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude, accuracy } = position.coords;
        setCooreds({
          lat: latitude,
          lng: longitude
        })
      });
    }
  }
  console.log("setCooreds", coords);
  return (

    <section className={Styles.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <div>
              <LabelText>Search Address</LabelText>
              {/* <input placeholder='Search Address' /> */}
              <MapsKeyWrapper>
                <GSearchInput setCoords={setCooreds} />
                <div className={Styles.mapImg}>
                  <CGoogleMap1 coords={coords} setCoords={setCooreds} />
                </div>
              </MapsKeyWrapper>
            </div>
            {/* <div className={Styles.mapImg}>
          
              <MapsKeyWrapper>
                <CGoogleMap1 coords={coords} setCoords={setCooreds} />

              </MapsKeyWrapper>
            </div> */}
            <div>
              <MButton
                onClick={DetectCurrentLocation}
                sq={"true"} variant="outline" height="auto" size="small">
                <Text className={Styles.locationText}>Detect My Location</Text>
              </MButton>
            </div>

          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className={Styles.box}>
            {/* <div className={Styles.AddressTitle}>Address Details</div> */}
            <Stack spacing={2}>
              <Box>
                <LabelText>Address ( Hno , street)  </LabelText>
                {/* <input
                  ref={userAddressNameRef}
                  placeholder='House/Flat Number / Street name'

                /> */}
                <Input
                  ref={userAddressNameRef}
                  placeholder='House/Flat Number / Street name'
                />
              </Box>
              <div>
                <LabelText>Address ( Area, City , Pincode) </LabelText>
                <Input placeholder='Area / Landmark' ref={userAddressAreaRef} />
              </div>
              <div>
                <LabelText>Phone Number</LabelText>
                <Input placeholder='Phone Number' ref={userMobileRef} />
              </div>
              <div>
                <LabelText>Name</LabelText>
                <Input placeholder='Name' ref={userNameRef}

                />
              </div>
              <MButton onClick={submitAddres}>
                Save
              </MButton>
            </Stack>

          </div>
        </Grid>

      </Grid>

    </section>

  );
};
export default AddNewAddress1