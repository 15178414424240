import React from "react";
import {
  CategoriesList,
  MostPopularCompoment,
  SeeMore,
} from "../../components/pages/home/app";
import { FooterCompoment } from "../../components/layout/footer/Index";
import { TopNavComponent } from "../../components/layout/header/Index";
import Ripple from "material-ripple-effects";
import { useSelector } from "react-redux";
import HeaderTabsComponent from "../../components/headerTabsComponent/headerTabsComponent";
// import ImageMasonry from "../../components/masonry/page";
// import MarqueAnimation from "../../components/marque-effect/MarqueAnimation";
// import DiscountSection from "../../components/home/DiscountSection";
import MobileHeaderSection from "../../components/layout/mobileHeaderSection/page";
import { Stack } from "@mui/material";
import HomePageCategories from "../../components/HomePageCategoryGrid/HomePageCategories";
import GetNearByShops1 from "../../components/GetNearByShops/GetNearByShops1";


export default function Home() {
  return (
    <React.Fragment >
      <div className="animate padding2">
        <TopNavComponent />
        <Stack rowGap={3}>
          <MobileHeaderSection />
          <HeaderTabsComponent />
          <HomePageCategories />
          <GetNearByShops1 />
          {/* <DiscountSection /> */}
          {/* <ImageMasonry /> */}
          {/* <MarqueAnimation /> */}
          <SeeMore />
       
          <MostPopularCompoment />
        </Stack>


        <FooterCompoment />
      </div>
    </React.Fragment>
  );
}
