import React, { useRef, useState } from 'react'
import { Autocomplete, LoadScript, useJsApiLoader, StandaloneSearchBox } from '@react-google-maps/api'
import Styles from "./GSearchInput.module.scss";
const libraries = ["places"];
function GSearchInput(props) {
    const { setCoords } = props
    const autocompleteRef = useRef(null);
    const [searchResult, setSearchResult] = useState("")

    // const { isLoaded, loadError } = useJsApiLoader({
    //     googleMapsApiKey: "AIzaSyB2gIWgH0pXIdx8NWNi9IfOMQ_2rSvzGMs",
    //     version: "weekly",
    //     libraries: ["places"],
    // })
    const onLoad = (autocomplete) => {
        autocompleteRef.current = autocomplete;
        console.log("Autocomplete loaded:", autocomplete);
    };

    const onPlaceChanged = () => {
        console.log("trigger");
        if (autocompleteRef.current) {
            const places = autocompleteRef.current.getPlaces();
            console.log("Place selected:", places);
            let lat = places[0].geometry.location.lat()
            console.log("lat", lat);
            setCoords((prev) => ({
                ...prev,
                lat: places[0].geometry.location.lat(),
                lng: places[0].geometry.location.lng()
            }))
            // Handle selected place details here
        }
    };
    const onInput = (e) => {
        console.log("User input:", e.target.value);
    };
    // if (loadError) {
    //     return <div>Error loading Google Maps API</div>;
    // }

    // if (!isLoaded) {
    //     return <div>Loading...</div>;
    // }

    return (
        <>

            <Autocomplete
                onLoad={onLoad}
                onPlacesChanged={onPlaceChanged}
                ref={autocompleteRef}
            // style={{ zIndex: 1002 }}
            >
                <input
                    className={Styles.input}
                    placeholder="Search a place"
                    onInput={onInput}
                />
            </Autocomplete>

            {/* <LoadScript    googleMapsApiKey="AIzaSyB2gIWgH0pXIdx8NWNi9IfOMQ_2rSvzGMs" libraries={libraries}
            >

                <StandaloneSearchBox
                    onLoad={onLoad}
                    onPlacesChanged={onPlaceChanged}
                    ref={autocompleteRef}

                >
                    <input
                        className={Styles.input}
                        placeholder="Search a place"
                        onInput={onInput}
                    />
                </StandaloneSearchBox>
            </LoadScript> */}
            {/* <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
                ref={autocompleteRef}
            >
                <input className={Styles.input

                } />
            </Autocomplete> */}
            {/* 
            {
                isLoaded &&
                <Autocomplete
                    onLoad={onLoad}
                    onPlaceChanged={onPlaceChanged}
                    ref={autocompleteRef}
                >
                    <input className={Styles.input

                    } />
                </Autocomplete>
            } */}
        </>
    )
}

export default GSearchInput