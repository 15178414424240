import React from 'react'
import Styles from "./GridLayout.module.scss"
import CDivider from '../../atoms/Divider/Divider'
import { Box, Stack } from '@mui/material'
import OrdersItemCard from './OrdersItemCard'
import MButton from '../../atoms/button/MButton'
import { Link } from 'react-router-dom'
function GridLayout(props) {
    const { section1, section2, section3, section4 } = props

    const ItemId = section1?.item_id ? section1?.item_id?.split(",") : [];
    const ItemName = section1?.item_name ? section1?.item_name?.split(",") : [];
    const ItemPrice = section1?.item_price?.split(",");
    const ItemQty = section1?.item_quantity?.split(",");

    const formArray = ItemName?.map((item, index) => ({
        item_id: ItemId[index],
        item_name: ItemName[index] || "",
        item_price: ItemPrice[index] || "",
        item_quantity: ItemQty[index] || "",
    })
    )
    const callBtn = (phone) => {
        window.open(`tel:${phone}`)
    }
    return (
        <div className={Styles.mainContainer}>

            <div className={Styles.gridBox}>
                <section className={Styles.section_1}>
                    <Stack rowGap={2}>
                        {formArray.map((item) => (<OrdersItemCard item={item} />))}
                    </Stack>
                </section>

                <section className={Styles.section_2}>
                    <Stack rowGap={2} >
                        {section2}
                        <CDivider />
                    </Stack>
                </section>

                <section className={Styles.section_3}>
                    {section3}
                </section>
            </div>

            <div className={Styles.helpSection}>
                <MButton variant="secondary" height="inherit" onClick={() => callBtn(8897966062)}>
                    Support Call
                </MButton>

            </div>
        </div>
    )
}

export default GridLayout