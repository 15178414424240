


import Text from "../../components/atoms/text/Text";
import {
  BorderColorOutlined,
  Check,
  ErrorOutlineOutlined,
  LogoutOutlined,
} from "@mui/icons-material";
import { Box, Grid, Modal, Stack } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Styles from "./page.module.scss";
import { useState } from "react";
import { useSelector } from "react-redux";

import PageHeaderComponent from "../../components/shared/PageHeaderComponent/PageHeaderComponent";
import CustomeModal from "../../components/atoms/Modal/CustomeModal";
import LogoutComponent from "../../components/logout/Logout";
import AddNewAddress1 from "../../components/molecules/AddressCard/AddNewAddress1";
import { useGetAllProductsQuery } from "../../store/slice";


const tabStyle = {
  fontWeight: "700",
  fontSize: "0.875rem",
  color: "#767676",
  textTransform: "capitalize",
};
   
export default function Profile() {
  const userProfileData = useSelector((state) => state.products.userData)

  const [value, setValue] = useState("1");
  const [openlogout, setOpenLogout] = useState(false)
  const [openAddress, setOpenAddress] = useState(false)

 
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const logoutBtn = () => {
    setOpenLogout(!openlogout)
  }
  return (
    <>
      <PageHeaderComponent back={true} title="Profile" />
      <Grid container columnSpacing={8} rowSpacing={3}>
        <Grid item sm={4} >

          <Stack pl={"5px"} direction="row" spacing={2} alignItems="center">
            <LogoutOutlined />
            <Box onClick={logoutBtn} sx={{ cursor: "pointer" }}>
              <Text>Logout</Text>
            </Box>
          </Stack>
          {/* </Stack> */}
        </Grid>


        <Grid item xs={12} sm={8}>
          <Stack spacing={4} >
            <Text className={Styles.bld2Xl}>Account settings</Text>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2.5 }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab sx={tabStyle} label="Profile details" value="1" />
                    <Tab sx={tabStyle} label="Address" value="2" />
                    <Tab sx={tabStyle} label="Login & Security" value="3" />
                    <Tab
                      sx={tabStyle}
                      label="Communication preferences"
                      value="4"
                    />
                  </TabList>
                </Box>
                <TabPanel value="1" sx={{ px: 0 }}>
                  <Stack spacing={4}>
                    <Card title="Personal Information">
                      <Stack spacing={3}>
                        <Stack>
                          <Text className={Styles.txtMd} color="#929292">
                            Name
                          </Text>
                          <Text className={Styles.txtXl} color="#484848">
                            {userProfileData?.name}
                          </Text>
                        </Stack>
                        <Stack>
                          <Text className={Styles.txtMd} color="#929292">
                            Date of birth
                          </Text>
                          <Text className={Styles.txtXl} color="#484848">
                            26 Dec 2000
                          </Text>
                        </Stack>
                        <Stack>
                          <Text className={Styles.txtMd} color="#929292">
                            Gender
                          </Text>
                          <Text className={Styles.txtXl} color="#484848">
                            Male
                          </Text>
                        </Stack>
                      </Stack>
                    </Card>
                    <Card title="Email">
                      <Stack spacing={3}>
                        <Stack>
                          <Text className={Styles.txtMd} color="#929292">
                            Email Address
                          </Text>
                          <Text className={Styles.txtXl} color="#484848">
                            {userProfileData?.email}
                          </Text>
                        </Stack>

                        <Text className={Styles.bld} color="#f26a2f">
                          Unverified
                        </Text>
                      </Stack>
                    </Card>
                    <Card title="Mobile">
                      <Stack spacing={3}>
                        <Stack>
                          <Text className={Styles.txtMd} color="#929292">
                            Mobile
                          </Text>
                          <Text className={Styles.txtXl} color="#484848">
                            {userProfileData?.mobile_number}
                          </Text>
                        </Stack>

                        <Text className={Styles.bld} color="#0a8a00">
                          Verified
                        </Text>
                      </Stack>
                    </Card>
                  </Stack>
                </TabPanel>
                <TabPanel value="2" sx={{ px: 0 }}>
                  <Card title="Billing address" editClick={() => setOpenAddress(true)}>
                    <Stack>
                      <Text className={Styles.txtMd} color="#929292">
                        Address
                      </Text>
                      <Text>{userProfileData?.address}</Text>
                      <Text>{userProfileData?.user_area}</Text>

                    </Stack>
                  </Card>
                </TabPanel>
                <TabPanel value="3" sx={{ px: 0 }}>
                  <Stack spacing={3}>
                    <Card title="Billing address">
                      <Stack>
                        <Text className={Styles.txtLg} color="#767676">
                          Password
                        </Text>
                        <Text>******</Text>
                      </Stack>
                    </Card>
                    <Card title="Delete account">
                      <Text className={Styles.txtXl} color="#484848">
                        You can remove your IKEA Family account at any time.
                        This will delete your account, purchase history and all
                        information connected to it.
                      </Text>
                    </Card>
                  </Stack>
                </TabPanel>
                <TabPanel value="4" sx={{ px: 0 }}>
                  <Stack spacing={3}>
                    <Card title="Shopping preferences">
                      <Stack>
                        <Text className={Styles.txtLg} color="#767676">
                          Preffered store
                        </Text>
                        <Text className={Styles.txtXl} color="#484848">
                          Hyderabad
                        </Text>
                      </Stack>
                    </Card>
                    <Card title="Newsletter subscriptions">
                      <Stack spacing={3}>
                        <Text className={Styles.bldLg} color="#484848">
                          Receive news, offers and inspiration from IKEA.
                        </Text>
                        <Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Check
                              sx={{ fontSize: "1rem", color: "#0a8a00" }}
                            />
                            <Text className={Styles.txtXl} color="#484848">
                              via email
                            </Text>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Check
                              sx={{ fontSize: "1rem", color: "#0a8a00" }}
                            />
                            <Text className={Styles.txtXl} color="#484848">
                              via SMS
                            </Text>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Check
                              sx={{ fontSize: "1rem", color: "#0a8a00" }}
                            />
                            <Text className={Styles.txtXl} color="#484848">
                              Direct mail
                            </Text>
                          </Stack>
                        </Stack>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          className={Styles.grayBoxLg}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={3}
                          >
                            <ErrorOutlineOutlined
                              sx={{ fontSize: "1.8rem", color: "#484848" }}
                            />
                            <Text className={Styles.txtLg} color="#11111">
                              Do not want to receive any marketing communication
                              from IKEA?
                            </Text>
                          </Stack>
                          <button className={Styles.btn}>
                            Unsubscribe all
                          </button>
                        </Stack>
                      </Stack>
                    </Card>
                    <Card title="Home and interests">
                      <Text className={Styles.bldLg} color="#484848">
                        Get better product recommendations by providing us with
                        more information about you
                      </Text>
                    </Card>
                  </Stack>
                </TabPanel>
              </TabContext>
            </Box>
          </Stack>
        </Grid>
      </Grid>

      {
        <CustomeModal show={openlogout} setShow={setOpenLogout}>
          <LogoutComponent cancel={setOpenLogout} />
        </CustomeModal>
      }
      {
        <CustomeModal title="Add Address" outSide={false} setShow={setOpenAddress} show={openAddress} >
          <AddNewAddress1 close={() => setOpenAddress(false)} profileData={userProfileData} />
        </CustomeModal>
      }

      {/* <Modal
        open={openAddress}
        onClose={() => setOpenAddress(false)}
      >
        <AddNewAddress1 close={() => setOpenAddress(false)} profileData={userProfileData} />
      </Modal> */}

      {/* {
        openAddress &&
        <div className={Styles.addModal}>
          <AddNewAddress1 close={() => setOpenAddress(false)} profileData={userProfileData} />
        </div>
      } */}


    </>
  );
}

function Card({ children, title, editClick }) {
  return (
    <Stack className={Styles.card}>
      <Stack>
        <Stack
          mb={3.5}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text className={Styles.bldXl}>{title}</Text>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ cursor: "pointer" }} onClick={editClick && editClick}>
            <BorderColorOutlined sx={{ fontSize: "1rem" }} />
            <Text className={Styles.bldSm}>Edit</Text>
          </Stack>
        </Stack>
        {children}
      </Stack>
    </Stack>
  );
}
