
import MenuCheckbox from "../atoms/menu/MenuCheckbox";
import { ExpandMore, TuneOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Skeleton,
  Slider,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { filters } from "./data";
import { useGetAllProductsQuery } from "../../store/slice";
import Input from "../atoms/Input/Input";
import PopoverComp from "../atoms/popover/Popover";
import useDebounce from "../../hooks/useDebouce";
import Styles from "./filter.module.scss";
import Text from "../atoms/text/Text";
import ProductCard from "../shared/productCard/Index";
import SkeletonProduct from "../atoms/Skeletons/SkeletonProduct";
import MButton from "../atoms/button/MButton";

const MAXPRICE = 200000;
const MINPRICE = 0;

export default function Filters() {
  const [open, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [search, setSearch] = useState("");
  const [price, setPrice] = useState([0, 200000]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [indexNumber, setIndexNumber] = useState(50)

  const debouncedPrice = useDebounce(price, 1000);
  const debouncedSearch = useDebounce(search, 1000);
  const debouncedSelectedFilters = useDebounce(selectedFilters, 500);

  const matches = useMediaQuery("(min-width:600px)");

  const { data: getAllProducts } = useGetAllProductsQuery()
  console.log("getAllProducts", getAllProducts);


  useEffect(() => {
    setShowSkeleton(true);
    setTimeout(() => {
      setShowSkeleton(false);
    }, 1000);
  }, [debouncedPrice, debouncedSearch, debouncedSelectedFilters]);

  const filterNamesArr = debouncedSelectedFilters.map((filter) => filter.name);

  const filteredProducts =
    debouncedSelectedFilters.length === 0
      ? getAllProducts
      : getAllProducts?.filter((product) =>
        filterNamesArr.includes(product.item_category)
      );

  const searchFilteredProducts = filteredProducts?.filter(
    (product) =>
      product.item_name?.toLowerCase()?.includes(debouncedSearch?.toLowerCase()) ||
      product.item_description?.toLowerCase()?.includes(debouncedSearch?.toLowerCase())
  );

  const priceFilteredProducts = searchFilteredProducts?.filter(
    (product) =>
      product.item_price >= debouncedPrice[0] &&
      product.item_price <= debouncedPrice[1]
  );

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePriceChange = (event, newValue) => {
    setPrice(newValue);
  };

  console.log("price", price);

  const handleCategoryChange = (e, item) => {
    if (e.target.checked) {
      setSelectedFilters((state) => {
        return [...state, item];
      });
    }

    if (!e.target.checked) {
      setSelectedFilters((state) => {
        const newState = state.filter((s) => s.id !== item.id);
        return newState;
      });
    }
  };

  const handleDelete = (e, item) => {
    setSelectedFilters((state) => {
      const newState = state.filter((s) => s.id !== item.id);
      return newState;
    });
  };

  const handleRemoveAllSelectedFilters = () => {
    setSelectedFilters([]);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(!open);
  };
  const moreBtn = (() => setIndexNumber((prev) => prev + 50))

  return (
    <>
      <Box display={{ xs: "block", sm: "none" }} mt={{ xs: 3, sm: "0" }}>
        <Input placeholder="Search for products" onChange={handleSearch} />
      </Box>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-start"
        alignItems="center"
        mt={{ xs: 2, sm: 6 }}
        // overflow="scroll"
        sx={{ scrollbarWidth: "none", overflowX: "scroll", py: "4px" }}
        mb={3}
      >
        <PopoverComp label="Price">
          <Box p="15px">
            <Stack width={200} spacing={1}>
              <p>
                ₹{price[0]} - ₹{price[1]}
              </p>
              <Slider
                value={price}
                onChange={handlePriceChange}
                valueLabelDisplay="auto"
                max={MAXPRICE}
                min={MINPRICE}
                sx={{ color: "black" }}
              />
            </Stack>
          </Box>
        </PopoverComp>
        {filters.map((filter) => (
          <MenuCheckbox
            key={filter.category}
            label={filter.category}
            list={filter.filter}
            onFilterChange={handleCategoryChange}
            selectedFilters={selectedFilters}
            onToggleDrawer={toggleDrawer(true)}
            matches={matches}
          />
        ))}

        <Button
          sx={{
            backgroundColor: "#eeeeee",
            color: "black",
            borderRadius: "18px",
            textTransform: "capitalize",
            padding: "0.5rem 1.5rem",
            fontWeight: "600",
            fontSize: "0.8rem",
            textWrap: "noWrap",
            minWidth: "fit-content",
          }}
          onClick={toggleDrawer(true)}
          endIcon={<TuneOutlined />}
        >
          All Filters
        </Button>
        <Box display={{ xs: "none", sm: "block" }} height="100%">
          <Input placeholder="Search for products" onChange={handleSearch} />
        </Box>
      </Stack>
      <Divider />
      <Stack
        my={3}
        direction="row"
        spacing={3}
        alignItems="center"
        overflow="scroll"
        sx={{ scrollbarWidth: "none" }}
      >
        {selectedFilters.map((filter) => (
          <Button
            key={filter.id}
            sx={{
              backgroundColor: "#eeeeee",
              color: "black",
              borderRadius: "25px",
              textTransform: "capitalize",
              padding: "0.4rem 1.5rem",
              minHeight: "2.5rem",
              minWidth: "fit-content",

              fontWeight: "600",
              fontSize: "0.75rem",
              border: "2px black solid",
            }}
            startIcon={<CloseIcon />}
            onClick={(e) => handleDelete(e, filter)}
          >
            {filter.name}
          </Button>
        ))}
        {selectedFilters.length > 0 && (
          <Button
            sx={{
              backgroundColor: "#eeeeee",
              color: "black",
              borderRadius: "25px",
              minHeight: "2.5rem",
              textTransform: "capitalize",
              padding: "0.4rem 1.5rem",
              fontWeight: "600",
              fontSize: "0.75rem",
              textWrap: "noWrap",
              minWidth: "fit-content",
            }}
            onClick={handleRemoveAllSelectedFilters}
          >
            Clear all
          </Button>
        )}
      </Stack>

      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        anchor={matches ? "right" : "bottom"}
        sx={{
          ".MuiDrawer-paper": {
            borderRadius: { xs: "8px 8px 0px 0px", sm: "8px 0px 0px 8px" },
          },
        }}
      >
        <Box
          width={{ xs: "100%", sm: "480px" }}
          sx={{
            borderRadius: "25px",
            overflowY: "scroll",
            scrollbarWidth: "none",
          }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            p={3}
            spacing={6}
            alignItems="center"
            position="relative"
          >
            <Text className={Styles.txtBldLg}>Filter and Sort</Text>
            <div className={Styles.cancelIconBox}>
              <IconButton>
                <CloseIcon onClick={toggleDrawer(false)} />
              </IconButton>
            </div>
          </Stack>
          <Divider />
          <Box p={4} mt={3}>
            <Accordion sx={{ boxShadow: "none" }}>
              <Box pt={1.7}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ px: 0 }}
                >
                  <Text className={Styles.txtBld}>Price</Text>
                </AccordionSummary>
              </Box>
              <AccordionDetails sx={{ p: 0, mt: 4.5 }}>
                <Stack maxWidth={"80%"} spacing={2}>
                  <Text>
                    ₹{price[0]} - ₹{price[1]}
                  </Text>
                  <Slider
                    value={price}
                    onChange={handlePriceChange}
                    valueLabelDisplay="auto"
                    max={MAXPRICE}
                    min={MINPRICE}
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>

            {filters.map((item) => (
              <Accordion
                key={item.category}
                sx={{
                  boxShadow: "none",

                  "&.MuiAccordion-root::before": {
                    display: "none",
                  },
                }}
              >
                <Box>
                  <Divider sx={{ my: 2.5 }} />
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ px: 0 }}
                  >
                    <Text className={Styles.txtBld}>{item.category}</Text>
                  </AccordionSummary>
                </Box>

                <AccordionDetails sx={{ p: 0, mt: 4.5 }}>
                  {item.filter.map((f) => (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      key={f.id}
                      mb={4}
                    >
                      <label
                        htmlFor={f.id}
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Text className={Styles.txt}>{f.name}</Text>

                        <Checkbox
                          name={f.name}
                          id={f.id}
                          onChange={(e) => handleCategoryChange(e, f)}
                          sx={{ p: 0 }}
                          checked={selectedFilters.find(
                            (value) => value.id === f.id
                          )}
                        />
                      </label>
                    </Stack>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Drawer>

      <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
        {
          !showSkeleton ?
            priceFilteredProducts?.slice(0, indexNumber)?.map((item) => {
              return (
                <Grid key={item.id} item xs={6} sm={4} md={3} lg={3}>
                  <ProductCard props={item} />
                </Grid>
              )
            }) :
            (
              new Array(10).fill(0).map((_, index) => (
                <Grid key={index} item xs={6} sm={4} md={3} lg={3}><SkeletonProduct /> </Grid>
              )

              )
            )
        }

        <Grid item xs={12}>
          <Stack alignItems={"flex-end"} py={2}>
            {
              priceFilteredProducts?.length > indexNumber &&
              <MButton
                onClick={moreBtn}
                height="38px" size="small" >
                See more
              </MButton>
            }
          </Stack>
        </Grid>

      </Grid>

    </>
  );
}
