
import React, { useEffect, useRef } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Styles from "./CustomeModal.module.scss";
import ModalTitle from './ModalTitle';
import IconBtn from '../IconBtn/IconBtn';
import { AspectRatio } from '@mui/icons-material';

function CustomeModal({ children, show, setShow, title, outSide=true }, props) {
    const ref = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                outSide &&  setShow(false);
            }
        };

        if (show) {
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [show, setShow]);

    useEffect(() => {
        document.body.style.overflow = show ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [show]);

    return (
        <div className={`${Styles.modalContainer} ${show ? Styles.show : ""}`}>
            <div ref={ref} className={`${Styles.review} ${show ? Styles.show : ""}`}>
                <div className={Styles.smallBar}></div>
                <div
                    className={Styles.closeBtn}
                    onClick={() => setShow(false)}
                >
                    <IconBtn sx={{ border: "1px solid", width: "30px", height: "30px", }} >
                        <CloseOutlinedIcon fontSize="small" />
                    </IconBtn>
                </div>
                {
                    title &&
                    <ModalTitle title={title} onClick={setShow} />
                }
                {children}
            </div>
        </div>
    );
}

export default CustomeModal;






// animation not working below code

// import React, { useEffect, useRef } from 'react'
// import Styles from "./CustomeModal.module.scss";
// import { motion } from 'framer-motion';

// function CustomeModal(props) {
//     const { children, show, setShow } = props
//     const ref = useRef(null);

//     useEffect(() => {
//         const leave = (event) => {
//             if (ref.current && !ref.current.contains(event.target)) {
//                 setShow(false);
//             }
//         };
//         document.addEventListener("mousedown", leave);
//     }, [ref]);
//     const toggleBodyScroll = (scrollEnabled) => {
//         document.body.style.overflow = scrollEnabled ? 'auto' : 'hidden';
//     };

//     useEffect(() => {
//         toggleBodyScroll(false);
//         return () => {
//             toggleBodyScroll(true);
//         };
//     }, []);
//     return (
//         <>
//             {
//                 // show &&
//                 <div className={`${Styles.modalContainer} ${show ? Styles.show : ""}`}>
//                     <div ref={ref}
//                         className={`${Styles.review} ${show ? Styles.show : ""}`}>
//                         <div className={Styles.smallBar}></div>
//                         {
//                             children
//                         }
//                     </div>

//                     {/* <motion.div
//                 className={Styles.body}
//                 variants={{}}
//             >
//                 {children}
//             </motion.div> */}
//                 </div>
//             }

//         </>
//     )
// }

// export default CustomeModal