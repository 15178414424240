import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Text from "../atoms/text/Text";
import Styles from "./headerTabsComponent.module.scss";
import "./Slider.scss"
import { Link } from "react-router-dom";

function SliderComponent(props) {
    const { data } = props
    var settings = {
        className: "center",
        infinite: false,
        centerPadding: "60px",
        slidesToShow: data?.length >= 8 ? 8 : data?.length <= 8 ? data?.length : 2,
        slidesToScroll: 4,
        swipeToSlide: true,
        // rows:1,

        // adaptiveHeight: true,
        afterChange: function (index) {
            console.log(
                `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
            );
        },
        // dots: true,

        speed: 500,

        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    // infinite: true,
                    // dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="slider-container">
            <Slider {...settings}>
                {
                    data?.map((item, index) => {
                        return (
                            <CategoryCard item={item} key={index} />
                        )
                    })
                }
            </Slider>
        </div>
    );
}

export default SliderComponent;


export const CategoryCard = (props) => {
    const { item } = props
    return (
        <>
            <Link to={`/category/${item.url}`}>
                <div className={Styles.categoryCardBox}>
                    <div
                        style={{ backgroundImage: `url(${item.img})` }}
                        className={Styles.img}
                    />
                    <Text className={Styles.titleText}>{item.title}</Text>
                </div>
            </Link>
        </>
    )
}